import React from 'react';
import { NavLink } from 'react-router-dom';
import './Values.css';

export default function Values() {
  return (
    <div className='values-container'>
      <div className='values-content'>

        {/* Split layout for h1 and values-primary-text */}
        <div className='values-primary-section'>
          <div className='values-title-container'>
            <h1 className='values-primary-header'>Our Values</h1>
          </div>
          <div className='values-text-container'>
            <p className='values-primary-text'>
            We believe that your home is more than just a place to live. It is a reflection 
            of who you are, what you love, and how you want to feel. That is why we create 
            interior designs that suit your personality, lifestyle, and budget. Whether you 
            need a cosy bedroom, a functional kitchen, or a stylish living room, we can help 
            you transform your space into your dream home. <br/><br/>

            We know that every client is unique and has different needs and preferences. 
            That is why we offer bespoke interior design services that are tailored to 
            your specific requirements. We listen to your ideas, understand your vision, 
            and collaborate with you throughout the process. We also provide you with a 
            3D visualization of your design before we start the implementation. With us, 
            you can be sure that you will get a custom-made interior design that matches 
            your expectations and satisfaction.<br/><br/>

            We care about the environment and the impact of our designs on the planet. 
            That is why we use sustainable materials, energy-efficient solutions, 
            and eco-friendly practices in our projects. We also aim to improve the indoor 
            air quality, natural light, and thermal comfort of your home. By choosing us, 
            you are not only investing in a beautiful interior design but also in a 
            healthier and greener future.
            </p>
          </div>
        </div>

        {/* Secondary header and text */}
        <h2 className='values-secondary-header'>Let's work together</h2>
        <p className='values-secondary-text'>
          We are always looking for new opportunities and are comfortable working
          internationally. Please, feel free to get in touch about beginning the
          proposal process.
        </p>

        {/* Button */}
        <button className='values-button'>
          <NavLink to='/contact'>Contact Us</NavLink>
        </button>

      </div>
    </div>
  );
}
