import './create.css'
import { useState } from 'react'
import { useFirestore } from '../../hooks/useFirestore'
import { useNavigate } from 'react-router-dom'
import { projectStorage } from '../../firebase/config'



export default function Create () {
  const Navigate = useNavigate()
  const {addDocument, response} = useFirestore('projects')
  const [name,setName] = useState('')
  const [details,setDetails] = useState('')
  const [formError, setFormError] = useState(null)
  const [coverPhoto, setCoverPhoto] = useState(null)  // Cover photo
  const [imageFiles, setImageFiles] = useState([]) // Store selected file
  const [uploadError, setUploadError] = useState(null)  // Error for file upload
  const [isUploading, setIsUploading] = useState(false)

  const handleFileChange = (e) => {
    setImageFiles(prevFiles => [...prevFiles, ...Array.from(e.target.files)])  // Append new files
  }

  const handleCoverChange = (e) => {
    setCoverPhoto(e.target.files[0])  // Only allow a single cover photo
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError(null)


    const project = {
      name,
      details,
      comments: [],
      images: [],  // Array to store image URLs
      coverPhoto: ''
    }

    setIsUploading(true)

    // Upload Cover Photo
    if (coverPhoto) {
      try {
        const coverRef = projectStorage.ref(`coverPhotos/${coverPhoto.name}`)
        const coverSnapshot = await coverRef.put(coverPhoto)
        const coverUrl = await coverSnapshot.ref.getDownloadURL()
        project.coverPhoto = coverUrl  // Add cover photo URL to project object
      } catch (err) {
        setUploadError('Failed to upload cover photo')
        setIsUploading(false)
        return
      }
    }

    // Upload rest of the photos
    if (imageFiles.length > 0) {
        const uploadPromises = imageFiles.map(async (file) => {
          const storageRef = projectStorage.ref(`images/${file.name}`)
          const snapshot = await storageRef.put(file)
          return await snapshot.ref.getDownloadURL()
        })
    
        try {
          const imageUrls = await Promise.all(uploadPromises)
          project.images = imageUrls
        } catch (err) {
          setUploadError('Failed to upload images')
          setIsUploading(false)
          return
        }
      }

    await addDocument(project)
    setIsUploading(false)
    if(!response.error){
      Navigate('/create')
    } else {
        setFormError(response.error)
    }
  }

  return (
    <div className='create-form'>
      <form onSubmit={handleSubmit}>
        <label>
          <span>Project Name</span>
          <input 
          required
          type="text"
          onChange={(e)=>setName(e.target.value)}
          value={name}/>
        </label>
        <label>
          <span>Project Details</span>
          <textarea 
          required
          type="text"
          onChange={(e)=>setDetails(e.target.value)}
          value={details}/>
        </label>
        <label>
          <span>Upload Image</span>
          <input 
            type="file"
            multiple
            onChange={handleFileChange}
          />
        </label>
        <label>
          <span>Upload Cover Photo</span>
          <input 
            type="file"
            onChange={handleCoverChange}
          />
        </label>

        {isUploading && <p>Uploading Image...</p>}
        <button className='btn' disabled={isUploading}>Add Project</button>
        {formError && <p className='error'>{formError}</p>}
        {uploadError && <p className='error'>{uploadError}</p>}
      </form>
    </div>
  )
}
