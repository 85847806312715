import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import './App.css';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

// PAGES AND COMPONENTS
import Navbar from './components/Navbar';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Index from './pages/index/Index';
import Values from './pages/values/Values';
import Work from './pages/work/Work';
import WorkDetails from './pages/workDetails/WorkDetails';
import Signup from './pages/signup/Signup';
import Login from './pages/login/Login';
import Logout from './pages/logout/Logout';
import Create from './pages/create/Create';
import { useAuthContext } from './hooks/useAuthContext';
import NotFound from './pages/notfound/NotFound';

function App() {
  const {authIsReady} = useAuthContext()
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const db = getFirestore();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsAuthenticated(true);

        // Fetch the user role from Firestore
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          if (userData.role === 'Admin') {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } else {
          console.log("No such document!");
          setIsAdmin(false);
        }
      } else {
        // User is signed out
        setIsAuthenticated(false);
        setIsAdmin(false);
      }
    });

    return () => unsubscribe(); // Clean up the listener on component unmount
  }, [db]);
  
  return (
    <div className="App">
      {authIsReady && (
      <Router>
        <div className='container'>
          <Navbar/>
          <Routes>
            <Route exact path='/' element={<Index/>} />
            <Route exact path='/index' element={<Index/>} />
            <Route exact path='/work' element={<Work/>} />
            <Route exact path='/about' element={<About/>} />
            <Route exact path='/values' element={<Values/>} />
            <Route exact path='/Contact' element={<Contact/>} />
            <Route exact path='/signup' element={<Signup/>} />
            <Route exact path='/login' element={<Login/>} />
            <Route exact path='/logout' element={<Logout/>} />
            <Route exact path='/create' element={isAuthenticated && isAdmin ? <Create/> : <Work/>} />
            <Route path="/projects/:id" element={<WorkDetails />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
      )}
    </div>
  );
}

export default App;
