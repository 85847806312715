import React from 'react';
import Portrait from '../../assets/3254ffa0-fce6-48c1-a0f6-b75644aff1ac.jpg';
import AboutText from './AboutText';
import styles from './About.module.css'

export default function About() {
  return (
    <div className={styles['about-container']}>
      <h1 className={styles['about-title']}>About</h1>
      <img src={Portrait} alt="Portrait" className={styles['about-image']} />
      <h2 className={styles['about-titlename']}>Dimitar Bozhanov</h2>
      <AboutText/>
    </div>
  );
}
