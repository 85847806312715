import styles from './WorkDetails.module.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { projectFirestore, projectStorage } from '../../firebase/config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const WorkDetails = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [fileInput, setFileInput] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageToRemove, setImageToRemove] = useState(null);
  const [expandedImage, setExpandedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const docRef = doc(projectFirestore, 'projects', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setProject(docSnap.data());
        } else {
          setError('Project not found');
        }
      } catch (err) {
        setError('Failed to load project data');
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [id]);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsAuthenticated(true);
        const userRef = doc(projectFirestore, 'users', user.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          setIsAdmin(userData.role === 'Admin');
        } else {
          setIsAdmin(false);
        }
      } else {
        setIsAuthenticated(false);
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (expandedImage) {
      // Disable scrolling
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      // Enable scrolling
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
    }
  
    // Cleanup in case the component unmounts
    return () => {
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
    };
  }, [expandedImage]);

const handleBackClick = () => {
      navigate(-1); // Navigate to the previous page
};

// Zoom function on images
const handleExpandImage = (index) => {
    setCurrentImageIndex(index);
    setExpandedImage(project.images[index]);
};

const handleCloseExpand = () => {
  setExpandedImage(null);
};

const handlePreviousImage = () => {
  const newIndex = (currentImageIndex - 1 + project.images.length) % project.images.length;
  setCurrentImageIndex(newIndex);
  setExpandedImage(project.images[newIndex]);
};

const handleNextImage = () => {
  const newIndex = (currentImageIndex + 1) % project.images.length;
  setCurrentImageIndex(newIndex);
  setExpandedImage(project.images[newIndex]);
};

const handleTouchStart = (e) => {
  setTouchStartX(e.touches[0].clientX);
};

const handleTouchMove = (e) => {
  setTouchEndX(e.touches[0].clientX);
};

const handleTouchEnd = () => {
  const swipeDistance = touchStartX - touchEndX;

  // Determine swipe direction
  if (swipeDistance > 50) {
    // Swipe left (next image)
    handleNextImage();
  } else if (swipeDistance < -50) {
    // Swipe right (previous image)
    handlePreviousImage();
  }

  // Reset touch positions
  setTouchStartX(0);
  setTouchEndX(0);
};

// Edit function
const handleEditClick = (field) => {
    setIsEditing(field);
    setEditValue(project[field] || '');
};

const handleSave = async () => {
    try {
      const docRef = doc(projectFirestore, 'projects', id);
  
      // Handle adding images
      if (fileInput.length > 0) {
        const fileUrls = await Promise.all(
          fileInput.map(async (file) => {
            const filePath = `Images/${id}_${file.name}`;
            const storageRef = ref(projectStorage, filePath);
            await uploadBytes(storageRef, file);
            return getDownloadURL(storageRef);
          })
        );
  
        await updateDoc(docRef, { images: [...(project.images || []), ...fileUrls] });
        setProject({ ...project, images: [...(project.images || []), ...fileUrls] });
        setFileInput([]); // Clear the file input after successful upload
      } else if (isEditing) {
        // Handle editing other fields
        if (isEditing === 'coverPhoto' && fileInput.length > 0) {
          const filePath = `coverPhotos/${id}_${fileInput[0].name}`;
          const storageRef = ref(projectStorage, filePath);
          await uploadBytes(storageRef, fileInput[0]);
          const fileUrl = await getDownloadURL(storageRef);
  
          await updateDoc(docRef, { coverPhoto: fileUrl });
          setProject({ ...project, coverPhoto: fileUrl });
        } else {
          await updateDoc(docRef, { [isEditing]: editValue });
          setProject({ ...project, [isEditing]: editValue });
        }
  
        setIsEditing(null);
        setEditValue('');
      }
    } catch (err) {
      setError('Failed to update project data');
    }
  };

  const handleFileChange = (e) => {
    setFileInput((prevFiles) => [...prevFiles, ...Array.from(e.target.files)]);
  };

  const handleRemoveFile = (fileToRemove) => {
    setFileInput((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  const handleRemoveImage = (imageUrl) => {
    setImageToRemove(imageUrl);
    setIsModalOpen(true);
  };

  const confirmRemoveImage = async () => {
    try {
      const docRef = doc(projectFirestore, 'projects', id);
      const updatedImages = project.images.filter((image) => image !== imageToRemove);
  
      await updateDoc(docRef, { images: updatedImages });
      setProject({ ...project, images: updatedImages });
      setImageToRemove(null);
      setIsModalOpen(false);
    } catch (err) {
      setError('Failed to remove image');
      setIsModalOpen(false);
    }
  };
  
  const cancelRemoveImage = () => {
    setImageToRemove(null);
    setIsModalOpen(false);
  };

  const updateImageOrder = async (newImages) => {
    try {
      const docRef = doc(projectFirestore, 'projects', id);
      await updateDoc(docRef, { images: newImages });
      setProject((prev) => ({ ...prev, images: newImages }));
    } catch (err) {
      setError('Failed to update image order');
    }
  };

  const handlePositionChange = (index, newPosition) => {
    const updatedImages = [...project.images];
    const [movedImage] = updatedImages.splice(index, 1);
    updatedImages.splice(newPosition - 1, 0, movedImage);

    updateImageOrder(updatedImages);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className={`${styles.workDetails}`}>
      <div className={styles.container}>
        <div className={styles.header}>
        <button className={styles.backButton} onClick={handleBackClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={styles.hollowArrow}
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </button>
          <h1 className={styles.title}>{project.name}</h1>
          {isAuthenticated && isAdmin && (
            <button className={styles.editButton} onClick={() => handleEditClick('name')}>
              Edit
            </button>
          )}
        </div>
        {isEditing === 'name' && (
          <div className={styles.editField}>
            <input
              type="text"
              className={styles.editInput}
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
            />
            <button className={styles.saveButton} onClick={handleSave}>
              Save
            </button>
          </div>
        )}

        <div className={styles.coverPhotoWrapper}>
          <img src={project.coverPhoto} alt="Cover" className={styles.coverPhoto} />
        </div>
      
        <div className={styles.detailsWrapper}>
          <p className={styles.details}>{project.details}</p>
          {isAuthenticated && isAdmin && (
            <button className={styles.editButton} onClick={() => handleEditClick('details')}>
              Edit
            </button>
          )}
        </div>
        {isEditing === 'details' && (
          <div className={styles.editField}>
            <textarea
              className={styles.editTextarea}
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
            />
            <button className={styles.saveButton} onClick={handleSave}>
              Save
            </button>
          </div>
      )}

      <div className={styles.imageGallery}>
          {project.images?.map((image, index) => (
            <div key={index} className={styles.gridItem}>
              <img 
              src={image} 
              alt={`Project ${index}`} 
              className={styles.galleryImage}
              onClick={() => handleExpandImage(index)}
              />
              {isAuthenticated && isAdmin && (
              <>
                <div className={styles.editPosition}>
                  <label htmlFor={`position-${index}`}>Position:</label>
                  <input
                    type="number"
                    id={`position-${index}`}
                    value={index + 1}
                    onChange={(e) => handlePositionChange(index, parseInt(e.target.value))}
                    className={styles.positionInput}
                    min="1"
                    max={project.images.length}
                  />
                </div>
                <button
                  className={styles.removeButton}
                  onClick={() => handleRemoveImage(image)}
                >
                  ×
                </button>
              </>
            )}
            </div>
          ))}
        {isAuthenticated && isAdmin && (
          <>
            <label htmlFor="file-upload" className={styles.customFileUpload}>
              Choose Files
            </label>
            <input
              id="file-upload"
              type="file"
              className={styles.imageUploadInput}
              accept="image/*"
              multiple
              onChange={handleFileChange}
            />
            <button className={styles.addImagesButton} onClick={handleSave}>
              Add Images
            </button>
            <div className={styles.previewContainer}>
              {fileInput.map((file, index) => (
                <div key={index} className={styles.previewItem}>
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index}`}
                    className={styles.previewImage}
                  />
                  <button
                    className={styles.removePreviewButton}
                    onClick={() => handleRemoveFile(file)}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </>
        )}
        {expandedImage && (
          <div
            className={styles.expandedImageOverlay}
            onClick={(e) => {
              if (!e.target.closest(`.${styles.expandedImageContainer}`)) handleCloseExpand();
            }}
          >
            <div
              className={styles.expandedImageContainer}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <button
                className={styles.closeButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCloseExpand();
                }}
              >
                ×
              </button>
              <img
                src={expandedImage}
                alt="Expanded view"
                className={styles.expandedImage}
                onClick={(e) => e.stopPropagation()}
              />
              <button
                className={styles.leftArrow}
                onClick={(e) => {
                  e.stopPropagation();
                  handlePreviousImage();
                }}
              >
                ←
              </button>
              <button
                className={styles.rightArrow}
                onClick={(e) => {
                  e.stopPropagation();
                  handleNextImage();
                }}
              >
                →
              </button>
            </div>
          </div>
        )}
        </div>
        {isModalOpen && (
          <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
              <p>Are you sure you want to delete this image?</p>
              <div className={styles.modalActions}>
                <button className={styles.confirmButton} onClick={confirmRemoveImage}>
                  Yes, Delete
                </button>
                <button className={styles.cancelButton} onClick={cancelRemoveImage}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkDetails;
