import styles from './navbar.module.css';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'; // Use NavLink instead of Link
import Logo from '../assets/logo 1_1.png';
import { useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import styled from 'styled-components';

export default function Navbar() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const navbarRef = useRef(null); // Create a ref for the navbar
  const location = useLocation();
  const transparentPaths = ['/', '/index']; // Add the paths where you want transparency
  const isTransparent = transparentPaths.includes(location.pathname);


  const db = getFirestore();

useEffect(() => {
    const navbar = navbarRef.current;
  
    const handleScroll = () => {
      const isSmallScreen = window.innerWidth <= 768; // Adjust breakpoint as needed
  
      if (!isSmallScreen) {
        if (window.scrollY > 0) {
          navbar.style.top = '-200px'; // Hide the navbar when scrolling down
        } else {
          navbar.style.top = '0'; // Show the navbar when at the top
        }
      } else {
        navbar.style.top = '0'; // Always show navbar on small screens
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup on component unmount
    };
}, []);

// Scroll button
useEffect(() => {
  const handleScroll = () => {
    if (window.scrollY > 50) {
        setShowBackToTop(true);
    } else {
        setShowBackToTop(false);
    }
  };
  
  window.addEventListener('scroll', handleScroll);
  
  return () => window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
}, []);

const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling effect
    });
};

// Remove scrolling when navbar is open
useEffect(() => {
  const body = document.body;
  
  if (isMenuOpen) {
      body.style.overflow = 'hidden';
  } else {
      body.style.overflow = '';
  }
  
  return () => {
      body.style.overflow = '';
  };
}, [isMenuOpen]);

useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsAuthenticated(true);
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          if (userData.role === 'Admin') {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } else {
          console.log("No such document!");
          setIsAdmin(false);
        }
      } else {
        setIsAuthenticated(false);
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
}, [db]);

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate('/work');
      })
      .catch((error) => {
        console.error('Sign out error', error);
      });
  };

  return (
    <nav ref={navbarRef} 
    className={`${styles.navbar} ${isTransparent ? styles.transparent : ''}`}>
      <div className={styles['navbar-header']}>
        <NavLink 
          to="/" 
          className={({ isActive }) => (isActive ? styles['no-underline'] : '')}
        >
          <img src={Logo} alt="main logo" className={styles.logo} />
        </NavLink>
        <button
          className={`${styles['burger-menu']} 
          ${isMenuOpen ? styles.original : ''}
          ${isTransparent ? styles.transparent : ''}`}
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
          aria-label="Toggle menu"
        >
          {isMenuOpen ? '✖' : '☰'}
        </button>
      </div>
      
      {isMenuOpen && (
        <div
          className={`${styles['dropdown-menu-container']}
          ${isMenuOpen ? styles.open : ''}
          `}
        >
          <div className={styles['dropdown-header']}>
          <NavLink
                to="/"
                className={({ isActive }) => (isActive ? styles.active : '')}
                onClick={() => setIsMenuOpen(false)}
              >
            <img src={Logo} alt="Logo" className={styles['dropdown-logo']} />
          </NavLink>
          </div>
          <ul className={styles['dropdown-menu-unorganized-list']}>
            <li className={styles['dropdown-menu-list-item']}>
              <NavLink
                to="/work"
                className={({ isActive }) => (isActive ? styles.active : '')}
                onClick={() => setIsMenuOpen(false)}
              >
                Work
              </NavLink>
            </li>
            <li className={styles['dropdown-menu-list-item']}>
              <NavLink
                to="/about"
                className={({ isActive }) => (isActive ? styles.active : '')}
                onClick={() => setIsMenuOpen(false)}
              >
                About
              </NavLink>
            </li>
            <li className={styles['dropdown-menu-list-item']}>
              <NavLink
                to="/values"
                className={({ isActive }) => (isActive ? styles.active : '')}
                onClick={() => setIsMenuOpen(false)}
              >
                Values
              </NavLink>
            </li>
            <li className={styles['dropdown-menu-list-item']}>
              <NavLink
                to="/contact"
                className={({ isActive }) => (isActive ? styles.active : '')}
                onClick={() => setIsMenuOpen(false)}
              >
                Contact
              </NavLink>
            </li>
            {isAuthenticated && isAdmin && (
              <li className={styles['dropdown-menu-list-item']}>
                <NavLink
                  to="/create"
                  className={({ isActive }) => (isActive ? styles.active : '')}
                  onClick={() => setIsMenuOpen(false)}
                >
                  Create
                </NavLink>
              </li>
            )}
            {isAuthenticated && (
              <li className={styles['dropdown-menu-list-item']}>
                <button
                  onClick={() => {
                    handleLogout();
                    setIsMenuOpen(false);
                  }}
                  className={styles['dropdown-logout-button']}
                >
                  Logout
                </button>
              </li>
            )}
          </ul>
        </div>
      )}

      {/* Original Menu for Larger Screens */}
      <ul className={`${styles['original-unorganized-list']}`}>
        <li className={`${styles['original-list-item']} ${isTransparent ? styles.transparent : ''}`}>
          <NavLink to="/work" className={({ isActive }) => (isActive ? styles.active : '')}>
            Work
          </NavLink>
        </li>
        <li className={`${styles['original-list-item']} ${isTransparent ? styles.transparent : ''}`}>
          <NavLink to="/about" className={({ isActive }) => (isActive ? styles.active : '')}>
            About
          </NavLink>
        </li>
        <li className={`${styles['original-list-item']} ${isTransparent ? styles.transparent : ''}`}>
          <NavLink to="/values" className={({ isActive }) => (isActive ? styles.active : '')}>
            Values
          </NavLink>
        </li>
        <li className={`${styles['original-list-item']} ${isTransparent ? styles.transparent : ''}`}>
          <NavLink to="/contact" className={({ isActive }) => (isActive ? styles.active : '')}>
            Contact
          </NavLink>
        </li>
        {isAuthenticated && isAdmin && (
          <li className={`${styles['original-list-item']} ${isTransparent ? styles.transparent : ''}`}>
            <NavLink to="/create" className={({ isActive }) => (isActive ? styles.active : '')}>
              Create
            </NavLink>
          </li>
        )}
        {isAuthenticated && (
          <li className={`${styles['original-list-item']} ${isTransparent ? styles.transparent : ''}`}>
            <button onClick={handleLogout} className={styles['logout-button']}>
              Logout
            </button>
          </li>
        )}
      </ul>
          
      {showBackToTop && !isMenuOpen && (
        <StyledWrapper>
          <button 
          className="button"
          onClick={scrollToTop}
          >
            <svg className="svgIcon" viewBox="0 0 384 512">
              <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
            </svg>
          </button>
        </StyledWrapper>
      )}
    </nav>
  );
}  

const StyledWrapper = styled.div`
  .button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #9D2933;
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 0px 4px rgba(255, 76, 92, 0.253);
    cursor: pointer;
    transition-duration: 0.3s;
    overflow: hidden;
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

  .svgIcon {
    width: 12px;
    transition-duration: 0.3s;
  }

  .svgIcon path {
    fill: white;
  }

  .button:hover {
    width: 140px;
    border-radius: 50px;
    transition-duration: 0.3s;
    background-color: #9D2933;
    align-items: center;
  }

  .button:hover .svgIcon {
    transition-duration: 0.3s;
    transform: translateY(-200%);
  }

  .button::before {
    position: absolute;
    bottom: -20px;
    content: "Back to Top";
    color: white;
    font-size: 0px;
  }

  .button:hover::before {
    font-size: 13px;
    opacity: 1;
    bottom: unset;
    transition-duration: 0.3s;
  }
`;