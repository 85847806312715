import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyDogUW7JBiIa9T426xOAj5QoTEMGjoHcz0",
    authDomain: "imidea-940fa.firebaseapp.com",
    projectId: "imidea-940fa",
    storageBucket: "imidea-940fa.appspot.com",
    messagingSenderId: "670089486155",
    appId: "1:670089486155:web:f7ad20ae2d9edc120a92ce",
    measurementId: "G-YRQP207G2K"
  };

  firebase.initializeApp(firebaseConfig)
  const projectFirestore = firebase.firestore()
  const projectAuth = firebase.auth()
  const timestamp = firebase.firestore.Timestamp
  const projectStorage = firebase.storage()

  export { projectFirestore, projectAuth, timestamp, projectStorage }