import { useState } from 'react'
import { useSignup } from '../../hooks/useSignup'

export default function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
//   const [displayName, setDisplayName] = useState('')
//   const [thumbnail, setThumbnail] = useState(null)
//   const [thumbnailError, setThumbnailError] = useState(null)
  const { signup, isPending, error} = useSignup()

//   const handleFileChange = (e) => {
//     setThumbnail(null)
//     let selected = e.target.files[0]

//     if (!selected) {
//       setThumbnailError('Please select a file')
//       return
//     }
//     if (!selected.type.includes("image")) {
//       setThumbnailError('Selected file must be an image')
//       return
//     }
//     if (selected.size > 100000) {
//       setThumbnailError("Image file size must be less than 100kb")
//       return
//     }

//     setThumbnailError(null)
//     setThumbnail(selected)
//     console.log("Thumbnail updated")

//   }

  const handleSubmit = (e) => {
    e.preventDefault()
    signup(email, password)
  }

  return (
    <div>
      <form className='auth-form' onSubmit={handleSubmit}>
        <h2>Sign up</h2>
        <label>
          <span>Email:</span>
            <input 
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"/>
        </label>
        <label>
          <span>Password:</span>
          <input 
              required
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"/>
        </label>
        {!isPending && <button className='btn'>Sign Up</button>}
        {isPending && <button className='btn' disabled>Loading</button>}
        {error && <div className='error'>{error}</div> }
      </form>
    </div>
  )
}
