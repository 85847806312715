import React from 'react';
import styles from './index.module.css';
import { Link } from 'react-router-dom';

export default function Index() {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Interior Design</h1>
      <div className={styles.content}>
        <div className={styles.section}>
          <p className={styles.text}>
           Your vision, your story! Interior design that turns your space into a dream retreat.
          </p>
          <Link className={styles.link} to="/work">Explore</Link>
        </div>
        <div className={styles.section}>
          <p className={styles.text}>
            Get in touch with us to get the answer to your questions.
          </p>
          <Link className={`${styles.link} ${styles.secondary}`} to="/contact">Contact Me</Link>
        </div>
      </div>
    </div>
  );
}
