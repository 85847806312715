import React, { useState } from 'react';
import { projectFirestore } from '../../firebase/config';
import styles from './Contact.module.css';

export default function Contact() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await projectFirestore.collection('contactSubmissions').add(formData);
      alert('Message sent successfully!');
      setFormData({ firstName: '', lastName: '', email: '', subject: '', message: '' });
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message.');
    }
  };

  return (
    <div className={styles.contactContainer}>
      <div className={styles.contactInfo}>
        <h2>Let's Work Together</h2>
        <p>Get in touch with us to get the answer to your questions.</p>
        <hr />
        <address>
          <p>Bristol, United Kingdom</p>
          <p>+44 775677 4210</p>
          <p>imidea.bristol@outlook.com</p>
        </address>
        <div className={styles.social}>
        <a href="https://www.instagram.com/dimitr.bozhanow?igshid=MzMyNGUyNmU2YQ%3D%3D" target="_blank" rel="noopener noreferrer">
         <i className="fab fa-instagram"></i>
        </a>
        </div>
      </div>
      <form onSubmit={handleSubmit} className={styles.contactForm}>
        <div className={styles.inputGroup}>
            <div>
                <label htmlFor="firstName">First Name</label>
                <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                />
            </div>
            <div>
                <label htmlFor="lastName">Last Name</label>
                <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                />
            </div>
        </div>
        <div className={styles.fullWidth}>
            <label htmlFor="email">Email</label>
            <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
            />
        </div>
        <div className={styles.fullWidth}>
            <label htmlFor="subject">Subject</label>
            <input
                type="text"
                name="subject"
                id="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
                required
            />
        </div>
        <div className={styles.fullWidth}>
            <label htmlFor="message">Message</label>
            <textarea
                name="message"
                id="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                required
            />
        </div>
        <div className={styles.buttonContainer}>
          <button type="submit" className={styles.submitButton}>
              Submit
          </button>
        </div>
    </form>
    </div>
  );
}
