import { useState, useEffect } from 'react'
import { projectAuth, projectFirestore } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const signup = async (email, password,) => {
    setError(null)
    setIsPending(true)
  
    try {
      // signup
      const res = await projectAuth.createUserWithEmailAndPassword(email, password)

      if (!res) {
        throw new Error('Could not complete signup')
      }

    //   // upload user thumbnail
    //   const uploadPath = `thumbnails/${res.user.uid}/${thumbnail.name}`
    //   const img = await projectStorage.ref(uploadPath).put(thumbnail)
    //   const imgUrl = await img.ref.getDownloadURL()

    //   // add display AND PHOTO_URL name to user
    //   await res.user.updateProfile({ displayName, photoURL: imgUrl })

      // create a user document
      await projectFirestore.collection('users').doc(res.user.uid).set({ 
        online: true,
        // displayName,
        // photoURL: imgUrl,
      })

      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    } 
    catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    setIsCancelled(false); // Ensure isCancelled is false on mount
    return () => setIsCancelled(true) // Cleanup function sets it to true on unmount
  }, [])

  return { signup, error, isPending }
}