import './work.css'
import { useState, useEffect } from 'react'
import { projectFirestore } from '../../firebase/config'
import { Link } from 'react-router-dom'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'

export default function Work() {
  const [projects, setProjects] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(null)

  useEffect(() => {
    const auth = getAuth()
  
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsAuthenticated(true)
        const userRef = doc(projectFirestore, 'users', user.uid)
        const userSnap = await getDoc(userRef)
  
        if (userSnap.exists()) {
          const userData = userSnap.data()
          setIsAdmin(userData.role === 'Admin')
        } else {
          setIsAdmin(false)
        }
      } else {
        setIsAuthenticated(false)
        setIsAdmin(false)
      }
    })
  
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const unsubscribe = projectFirestore
      .collection('projects')
      .orderBy('position') // Order projects by position
      .onSnapshot(snapshot => {
        if (snapshot.empty) {
          setError('No projects to display')
          setLoading(false)
        } else {
          const results = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          setProjects(results)
          setLoading(false)
        }
      }, err => {
        setError(err.message)
        setLoading(false)
      })

    return () => unsubscribe()
  }, [])

  const updatePosition = async (id, newPosition) => {
    try {
      const projectRef = projectFirestore.collection('projects').doc(id)
      await projectRef.update({ position: newPosition })

      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project.id === id ? { ...project, position: newPosition } : project
        )
      )
    } catch (err) {
      console.error('Failed to update position:', err)
    }
  }

  const handleDelete = async (id) => {
    try {
      await projectFirestore.collection('projects').doc(id).delete()
      setProjects(prevProjects => prevProjects.filter(project => project.id !== id))
    } catch (err) {
      setError('Failed to delete project')
    } finally {
      setConfirmDelete(null)
    }
  }

  const promptDeleteConfirmation = (id) => {
    setConfirmDelete(id)
  }

  const cancelDelete = () => {
    setConfirmDelete(null)
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div className='error'>{error}</div>
  }

  return (
    <div className="project-list">
      {projects.map(project => (
        <div key={project.id} className="project-container">
          <div className="project-card">
            {isAuthenticated && isAdmin && (
              <button
                onClick={() => promptDeleteConfirmation(project.id)}
                className="delete-button"
              >
                X
              </button>
            )}
            <Link to={`/projects/${project.id}`}>
              <div className="project-content">
                <h3 className="project-name">{project.name}</h3>
                {project.coverPhoto && (
                  <div className="image-wrapper">
                    <img
                      src={project.coverPhoto}
                      alt={`${project.name} cover`}
                      className="project-image"
                    />
                  </div>
                )}
              </div>
            </Link>
          </div>
          {isAuthenticated && isAdmin && (
            <div className="edit-position">
              <label htmlFor={`position-${project.id}`}>Position:</label>
              <input
                type="number"
                id={`position-${project.id}`}
                value={project.position}
                onChange={(e) => updatePosition(project.id, parseInt(e.target.value))}
                className="position-input"
              />
            </div>
          )}
        </div>
      ))}

      {/* Modal overlay for delete confirmation */}
      {confirmDelete && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>Are you sure you want to delete this project?</p>
            <button onClick={() => handleDelete(confirmDelete)}>Yes</button>
            <button onClick={cancelDelete}>No</button>
          </div>
        </div>
      )}
    </div>
  )
}
