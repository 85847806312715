import React from 'react'
import styles from "./About.module.css"

export default function AboutText() {
  return (
    <p className={styles['about-text']}>Welcome to my design world! I'm an interior designer with a strong background in architecture. My design journey kicked off in Bulgaria, where I delved into the ins and outs of architectural design. Now, I'm on the exciting path of pursuing a Master's degree in Design, and my passion for creativity and innovation is always evolving. <br/><br/>
        Although I started in architecture, my true love is interior design. I've successfully applied my knowledge to various domestic design projects. However, my focus is now on exploring the dynamic field of retail design, where I believe my skills and vision can truly shine. <br/><br/>
        Recently ended the challenges of a Master's program, I'm driven by a relentless desire to enhance my skill set. I take pride in being a self-motivated individual and a quick learner, always eager to explore new horizons in the design world. <br/><br/>
        Collaboration is fundamental to my approach. Whether working solo or as part of a team, I bring a mix of creativity and organizational skills to the table. I highly value diverse perspectives and ideas, understanding that true innovation comes from the collaborative synergy of unique minds. <br/><br/>
        This is my design journey, where each project is a chance to blend creativity and functionality into spaces that tell compelling stories. Turning concepts into captivating, tangible designs that leave a lasting impression.</p>
  )
}
