import { useLogout } from "../../hooks/useLogout"

export default function Logout() {
    const { logout } = useLogout()

    const handleLogOut = () => {
        logout()
    }

  return (
    <div>
      <button onClick={handleLogOut}>Logout</button>
    </div>
  )
}
